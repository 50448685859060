import styled from "styled-components";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../redux/actions/categoryActions";
import { useEffect } from "react";
//#region
const CategoryDiv = styled.div`
  //margin-top: 20rem;
  //margin: 5px 10px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin-top: 3rem !important;
  
`;

const CategoriesContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: .5rem !important;
  @media only screen and (max-width: 640px) {
    //height: 100px;
    width: 380px;
  }

`;
const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #cacaca;
`;
const EachCategory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 24%;

  margin-bottom: 2rem !important;
  @media only screen and (max-width: 768px) {
    margin-bottom: .5rem !important;
  }
  //margin: 2rem .1rem;
  cursor: pointer;
  & span {
    color: #212B36;

    font-family: 'Roboto';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 1rem;
    @media only screen and (max-width: 768px) {
      font-size: 0rem;
      margin-top: 0rem;
      visibility: hidden;
      
  }
  }
  &:hover {
    & span {
      color: black !important;
    }
    
  }
  @media only screen and (min-width: 640px) and (max-width: 980px) {
    width: 20%;
    margin: 0.5rem;
  }
  @media only screen and (max-width: 640px) {
    width: 48%;
    //margin: 0.5rem;
  }
`;
const Categories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;
const EachCategoryImage = styled.img`
  width: 100%;
  //max-width: 19.0625rem;
  //height: 12.8125rem;
  object-fit: contain;
  @media only screen and (max-width: 640px) {
    //height: 100px;
    height: 131px;
    width: 189px;
  }
  @media only screen and (max-width: 360px) {
    //height: 100px;
    height: 121px;
    width: 175px;
  }
`;
const Title = styled.span`
  color: #212B36;
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const SubTitle = styled.span`
  color: #D22424;
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 0.85rem;
  }
`;
//#endregion
function CategoriesCatalog(props) {
  const navigate = useNavigate()
  useEffect(() => {
    const getCat = async () => {
      if (props.categories.length === 0) await props.actions.getCategories();
    };
    getCat();
  }, []);
  const rootCategory =(seodesc)=>{
    navigate('/product-categories/'+seodesc)
    //navigate(`/search?${catId ? `&categoryid=${catId}` : ''}`) 
 }
  return (
    <CategoryDiv>
        <Title>Categories</Title>
        <SubTitle>Product Categories</SubTitle>      
        <CategoriesContent>         
          <Categories>
            {props.categories.filter(x=>x.homepage).map((cat, index) => {
              return (
                <EachCategory key={index} onClick={()=>rootCategory(cat.seodescription)}>
                  <EachCategoryImage src={`/${cat.image}`}></EachCategoryImage>
                </EachCategory>
              );
            })}
           
          </Categories>
        </CategoriesContent>
      </CategoryDiv>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    categories: state.categoryListReducer,
  };
}
export default connect(mapStateToProps,mapDispatchToProps)(CategoriesCatalog) ;


/*
 <EachCategory onClick={()=>rootCategory("07")}>
              <EachCategoryImage  src="/catalog/Categories/07.jpg"></EachCategoryImage>
              <span>Confectionery</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("09")}>
              <EachCategoryImage src="/catalog/Categories/09.jpg"></EachCategoryImage>
              <span>Non Food</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("08")}>
              <EachCategoryImage src="/catalog/Categories/08.jpg"></EachCategoryImage>
              <span>Bread & Cake</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("04")}>
              <EachCategoryImage src="/catalog/Categories/04.jpg"></EachCategoryImage>
              <span>Fruit & Veg</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("05")}>
              <EachCategoryImage src="/catalog/Categories/05.jpg"></EachCategoryImage>
              <span>Drinks</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("06")}>
              <EachCategoryImage src="/catalog/Categories/06.jpg"></EachCategoryImage>
              <span>Alcohol</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("03")}>
              <EachCategoryImage src="/catalog/Categories/03.jpg"></EachCategoryImage>
              <span>Frozen</span>
            </EachCategory>
            <EachCategory onClick={()=>rootCategory("01")}>
              <EachCategoryImage src="/catalog/Categories/01.jpg"></EachCategoryImage>
              <span>Grocery</span>
            </EachCategory>
*/