import styled from "styled-components";

const FeaturesImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  left: 0px;
  right: 0px;
  max-width: 1280px;
  margin-top: 3rem !important;
  @media only screen and (max-width: 768px) {
    margin-top: 1rem !important;
    flex-direction: row;
    flex-wrap:nowrap;
  }
`;
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;
  @media only screen and (max-width: 768px) {
    margin: 0rem 0rem;
  }
  
  & span {
    color: #637381;
    text-align: center;
    font-family: 'Roboto';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.7rem;
    
    }
  }
  & p {
    color: #212B36;

    text-align: center;
    font-family: 'Roboto';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: .62rem;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.8rem;
    
    }
  }
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    width: 30%;
    margin: 1rem auto;
    
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
    margin: 1rem auto;
    
  }
`;
const EachFeatureImage = styled.img`
  width: 42px;
  height: 42px;
  object-fit: contain;
  margin-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
    //height: 150px;
    
  }
`;
function FeaturesText() {
  return (
      <FeaturesImage>
        <EachFeature>
          <EachFeatureImage src="/catalog/FeatureImages/Freedelivery.png"/>
          <p>Free Delivery</p>
          <span>We're happy to let you know that you can enjoy free standard delivery on your order.</span>
        </EachFeature>
        <EachFeature>
          <EachFeatureImage src="/catalog/FeatureImages/wesupport.png"/>
          <p>100% Safe Quality</p>
          <span>Extra Cash&Carry Confidence: Quality Assured Safety Secured Your Premium Choice!</span>
        </EachFeature>
        <EachFeature>
          <EachFeatureImage src="/catalog/FeatureImages/Safequality.png"/>
          <p>We Support 24/7</p>
          <span>We're always here to help! Count on us for Extra Cash&Carry convenience, 24/7.</span>
        </EachFeature>
      </FeaturesImage>
  );
}

export default FeaturesText;
