
import styled from "styled-components";
import BrandsCarousel from "../HomeComponents/BrandsCarousel";

//#region Styles

const Container = styled.div`
 & h2 {
    color: #212B36;
    font-family: 'Roboto';
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 1rem;
  }
`;
const InformationDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  //flex-flow: row wrap;
  justify-content: center;
  //align-items: center;
  & p {
    color: #637381;

    font-family: 'Roboto';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & h1 {
    color: #ca1a21;
    font-size: 32px;
    font-weight: 900;
    text-transform: capitalize;
  }
  & a {
    color: #334862
  }
    
 
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const TitleContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
`;
const TitleLeft = styled.div`
  display: flex;
  flex-direction: column; 
`;
const Title = styled.span`
  color: #212B36;
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const SubTitle = styled.span`
  color: #D22424;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: .85rem;
  }
`;
//#endregion
function AboutContent() {
  return (
    <Container>
      <h2><strong>Who we are?</strong><br/> </h2>
      <InformationDiv>
        
        <DivContent>
          
          <p>Extra Cash & Carry is simply the best in self wholesale sector. We offer a broad range of catering products and services to the demands of professional customers, such as café shops and fish bars, kebab shops, restaurants and Chinese takeaways, sandwich bars, catering firms, as well as corner shops, supermarkets and Poundland shops and other wholesalers.<br/><br/></p>
          <p>Our first wholesale business, Best Catering Ltd, was established in 2009 as a small family firm. We started with 4,000 square feet and added new units in the following years. Eventually, we reached our current level of 70,000 square feet this year. Best Catering Ltd announced a 30 million turnover in 2017 and subsequently started Extra Cash & Carry with 80,000 square feet of operation area in 2018. Today, we are operating two catering wholesale centers in Tottenham.<br/><br/></p>
          <p>Extra Cash & Carry has a simple and efficient business concept: Cash and Carry wholesale, which is defined through its customer base and unique business model. All professional customers are allowed to purchase at Extra Cash & Carry. Our customers will be registered and provided with a customer card soon.<br/><br/></p>
          <p>We are a one-stop shop for our customers supplying all their food items and non-food items. We have a wide range of best-selling branded products from manufacturers.<br/><br/></p>
        </DivContent>
        <DivContent>
          
          <p>with a reputation for great quality. We stock more than 10,000 catering and retail products consisting of frozen and chilled foods, fresh groceries, meat, beers, wines & spirits, soft drinks, confectionery, crisps & snacks, household and non-foods such as packaging, cleaning products, and workware.<br/><br/></p>
          <p>Our customers can interact with Extra Cash & Carry in many ways. It can be made by placing and paying for their orders over the phone and collecting from our local branch. We also offer next day delivery service for our valuable customers. We are currently in the process of reviewing and improving our IT structure to facilitate faster and more efficient trading. This will be a big advantage for the customers.<br/><br/></p>
          <p>Browse our website for more information about our products with unbeatable prices.<br/><br/></p>
          <p>We firmly believe we provide the highest standard of service through incomparable customer experience and knowledge of the local business community’s needs. If you would like to take advantage of the best services that we have to offer you are more than welcome to Extra Cash & Carry 7/24 a week.<br/><br/></p>
        </DivContent>
      </InformationDiv>
      <TitleContent>
          <TitleLeft>
            <Title>
              {"Our Brands"}
            </Title>
            <SubTitle>
              {"Our exclusive brands"}
            </SubTitle>
          </TitleLeft>
        </TitleContent>
        <BrandsCarousel />
    </Container>
  );
}

export default AboutContent;
