import { useEffect, useState } from "react";
import { Badge } from "@material-ui/core";
import { Person, ShoppingBasket, Info } from "@material-ui/icons";
import Autocomplete from "./AutoComplete";
import styled from "styled-components";
import DropDownMenu from "../MenuComponents/DropDownMenu";
import CartMenuContent from "../MenuComponents/CartMenuContent";
import AccountMenuContent from "../MenuComponents/AccountMenuContent";
import AboutMenuContent from "../MenuComponents/AboutMenuContent";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../redux/actions/cartActions";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import MenuBar from "../MenuComponents/MenuBar";
import Announcement from "./Announcement";
//#region STYLES
const AnnouncementDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  position: relative;
  top: 0px;
  z-index: 9;
`;
const StickyContent = styled.div`
  display: flex;
  flex: 1;
  max-width: 1280px;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  position: ${(props) => (props.isSticky ? "sticky" : "relative")};
  top: 0px;
  z-index: 10;
  box-shadow: ${(props) =>
    props.isScrolled
      ? "0 2px 0px rgba(0, 0, 0, 0.1)"
      : "0 0px 0px rgba(0, 0, 0, 0)"};
  opacity: ${(props) =>
    props.isSticky ? 1 : 0}; /* Başlangıçta görünmez olacak */
  transition: opacity 0.45s ease;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;
//#region Left
const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2px;
`;

const LogoContainer = styled.div`
  display: flex;
  font-size: 60px;
  font-weight: 300;
  color: #333;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const LogoImage = styled.img`
  flex: 1;
  border-radius: 5px 5px 0 0;
  height: ${(props) => (props.isScrolled ? "3rem" : "5.2rem")};
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: height 0.3s ease;
`;
//#endregion
//#region center
const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 769px) {
    margin-bottom: 1rem;
  }
`;

const SearchBar = styled.div`
  display: flex;
`;

//#endregion
//#region Right
const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  & a {
    color: black;
    text-decoration-line: none;
  }
`;
const AccountInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
`;
const AccountInfoTest = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #00ab55;
  padding: 0.5rem;
  margin-left: 1rem;
`;
const AccountInfoTextTest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
`;
//#endregion
//#endregion
function NavBar(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSticky, setIsSticky] = useState(true);
  const [y, setY] = useState(0);
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  useEffect(() => {
    props.actions.getCartList();
  }, [props.actions]);
  useEffect(() => {
    const handleScroll = () => {
      setY(window.scrollY);
        if (y > (window.scrollY)) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
        
        if (window.scrollY > 0) {
          !isScrolled && setIsScrolled(true);
        } else {
          isScrolled && setIsScrolled(false);
        }
        
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);
  return (
    <>
      {width > 768 && (
        <AnnouncementDiv>
          <Announcement />
        </AnnouncementDiv>
      )}
      <StickyContent isSticky={isSticky} isScrolled={isScrolled}>
        <Container>
          <Left>
            <LogoContainer
              onClick={(_) => {
                navigate("/");
              }}
            >
              <LogoImage
                isScrolled={isScrolled}
                src={`/catalog/Products/logo.png`}
              ></LogoImage>
            </LogoContainer>
            {width <= 768 && (
              <ButtonSection>
                {/* SHOP ICON*/}
                {true && (
                  <AccountInfo>
                    <Person
                      style={{
                        width: "1.6rem",
                        height: "1.6rem",
                        color: "#00AB55",
                      }}
                    />
                    <AccountInfoText
                      onClick={() => {
                        navigate("/account/login");
                      }}
                    >
                      <h4>Account</h4>
                      <p>Login/Register</p>
                    </AccountInfoText>
                    {/*
                    <DropDownMenu
                    flexDirection="column"
                    icon={<Person />}
                    text={"ACCOUNT"}
                    contentWidth="10rem"
                    fontSize="0.8rem"
                    fontWeight="400"
                    right="0.8rem"
                    top="3.2rem"
                    minHeight="10vh"
                    contentLinks={<AccountMenuContent />}
                  />
                    */}
                  </AccountInfo>
                )}
                {true && (
                  <AccountInfoTest>
                    <Badge
                      style={{
                        marginBottom: "0px",
                        backgroundColor: "#00AB55",
                      }}
                      badgeContent={props.cart !== null && props.cart.length}
                      color="secondary"
                    >
                      <ShoppingBasket
                        style={{ width: "1.4rem", height: "1.4rem", color: "#fff" }}
                      />
                    </Badge>
                    <AccountInfoTextTest>
                      <h4>Cart</h4>
                      <p>£ 00.00</p>
                    </AccountInfoTextTest>
                    {/*
                     <Badge
                        style={{ marginBottom: "0px" }}
                        badgeContent={props.cart !== null && props.cart.length}
                        color="secondary"
                      >
                        <ShoppingBasket />
                      </Badge>
                  <DropDownMenu
                    flexDirection="column"
                    icon={
                      <Badge
                        style={{ marginBottom: "0px" }}
                        badgeContent={props.cart !== null && props.cart.length}
                        color="secondary"
                      >
                        <ShoppingBasket />
                      </Badge>
                    }
                    text={"BASKET"}
                    contentWidth = {(width <= 648 )?"20rem" : "38rem" }
                    fontSize="0.8rem"
                    fontWeight="400"
                    right="1rem"
                    top="3.2rem"
                    minHeight="50vh"
                    //contentLinks={<CartMenuContent />}
                  />
                    */}
                  </AccountInfoTest>
                )}
              </ButtonSection>
            )}
          </Left>
          <Center>
            <SearchBar>
              <Autocomplete />
            </SearchBar>
          </Center>
          {width > 768 && (
            <Right>
              <ButtonSection>
                {/* SHOP ICON*/}
                {
                  //props.settings.registeruser
                  true && (
                    <AccountInfo>
                      <Person
                        style={{
                          width: "2.1rem",
                          height: "2.1rem",
                          color: "#00AB55",
                        }}
                      />
                      <AccountInfoText
                        onClick={() => {
                          navigate("/account/login");
                        }}
                      >
                        <h4>Account</h4>
                        <p>Login/Register</p>
                      </AccountInfoText>

                      {/*
                     <DropDownMenu
                        flexDirection="row"
                        icon={<Person style={{width:'2rem',height:'2rem'}} />}
                        text={"Account"}
                        contentWidth="11rem" 
                        fontSize="1rem"
                        fontWeight="600"
                        right=".2rem"
                        top="4.4rem"
                        minHeight="5vh"
                        contentLinks={<AccountMenuContent />}
                      />
                    */}
                    </AccountInfo>
                  )
                }

                {
                  //props.cart.length > 0
                  true && (
                    <AccountInfoTest>
                      <Badge
                        style={{
                          marginBottom: "0px",
                          backgroundColor: "#00AB55",
                        }}
                        badgeContent={props.cart !== null && props.cart.length}
                        color="secondary"
                      >
                        <ShoppingBasket
                          style={{
                            width: "2rem",
                            height: "2rem",
                            color: "#fff",
                          }}
                        />
                      </Badge>
                      <AccountInfoTextTest>
                        <h4>Cart</h4>
                        <p>£ 00.00</p>
                      </AccountInfoTextTest>
                      {/* 
                        <DropDownMenu
                            flexDirection="row"
                            icon={
                              <Badge
                                style={{ marginBottom: "0px" }}
                                badgeContent={props.cart !== null && props.cart.length}
                                color="secondary"
                              >
                                <ShoppingBasket style={{width:'2rem',height:'2rem',color:'#fff'}} />
                              </Badge>
                            }
                            text={"CART"}
                            contentWidth="40rem"
                            fontSize="1rem"
                            fontWeight="600"
                            right="0.8rem"
                            top="3.2rem"
                            minHeight="50vh"
                            //contentLinks={<CartMenuContent />}
                          />
                      */}
                    </AccountInfoTest>
                  )
                }
              </ButtonSection>
            </Right>
          )}
        </Container>
        <MenuBar setOpenStoreLocation={props.setOpenStoreLocation} />
      </StickyContent>
    </>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCartList: bindActionCreators(cartActions.getCartList, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    settings: state.settingReducer,
    currentUser: state.currentUserReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
