import { Divider } from "@material-ui/core";
import { AddLocation, Call, Email,Facebook,Twitter,Instagram } from "@material-ui/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { colors } from "./../../res/values/values";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${colors.whiteColor};
  background-color:${colors.darkcolor};
  justify-content: center;
  align-items:center;
  overflow: hidden;
  width: 100%;
 
`;

const Image = styled.img`
  object-fit: contain;
  height:5rem ;
  width: 5rem;
`;
const Top = styled.div`
  padding: 10px;
  display: flex;
  flex: 1;
  margin: 0 5px;
  justify-content: space-between;
  max-width: 1280px;
  flex-direction:column ;
  width: 90%;
  @media only screen and (min-width: 420px) {
    width: 100%;
    
    //margin: 0 40px;
  }
  @media only screen and (min-width: 600px) {
    flex-direction: row;
    //margin: 0 40px;
  }
  

`;
const ColumnList = styled.ul`
 
  list-style-type: none;
  margin-left: 10px;
  margin-right:10px ;
  padding: 0;
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  justify-content:center;
  @media only screen and (max-width: 600px) {
    width: 100%;
    align-items: center;
    text-align: center;
    //margin: 0 40px;
  }

`;
const ColumnListTitle = styled.li`
  font-size: 0.9375rem;
  //font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: ${colors.secondryColor};
  @media only screen and (max-width: 768px) {
    font-size:  0.875rem;
  }
`;
const ColumnListItem = styled.li`
  margin-bottom: 5px;
  letter-spacing: 1px;
  display: flex;
  font-size: 0.875rem;
  @media only screen and (max-width: 768px) {
    
  }
  & a {
    font-size: 0.875rem;
    text-decoration: none;
  }
  & a:hover {
     color: ${colors.darkcolor}; 
  }
`;
const Bottom = styled.div`
    padding:5px ;
    background-color: ${colors.darkcolor};
    text-align:center ;
    margin-top:1px ;
  & p {
    margin: 8px 0;
    color: #ffffff;
    font-size: .9em;
    @media only screen and (max-width: 768px) {
      font-size: .8em;
    }
    & a {
      color: #ffffff;
      font-size: .9em;
      text-decoration: none;
      @media only screen and (max-width: 768px) {
        font-size: .8em;
      }
    }
    & a:hover {
     color: ${colors.secondryColor}; 
  }
  }
`;
function Footer(props) {
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  return (
    <Container width={width}>
      <Top>
        <ColumnList>
          <Image onClick={(_) => navigate("/")}
                alt=""
                loading="lazy"
                title="homepagebanner"
                src={`./catalog/Products/logo.png`} />
          <ColumnListTitle>Extra Cash and Carry</ColumnListTitle>
          <ColumnListItem>
            Unit A, Brantwood Road N17 0YD
          </ColumnListItem>
          <ColumnListItem>
            <a style={{color:"white"}} href="mailto:sales@extracashandcarry.com">
              sales@extracashandcarry.com
            </a>
          </ColumnListItem>
          <ColumnListItem>
            Phone:02039376970
          </ColumnListItem>
          
        </ColumnList>
        <ColumnList>
          <ColumnListTitle>Privacy & Terms</ColumnListTitle>
          <ColumnListItem>
            Privacy Policy
          </ColumnListItem>
          <ColumnListItem>
            Payment Policy
          </ColumnListItem>
          <ColumnListItem>
            Shipping Policy
          </ColumnListItem>
          <ColumnListItem>
            Terms & Conditions
          </ColumnListItem>
        </ColumnList>
        <ColumnList>
          <ColumnListTitle>Services</ColumnListTitle>
          <ColumnListItem>
            FAQ
          </ColumnListItem>
          <ColumnListItem onClick={(_) => navigate("/about")}>
            About Us
          </ColumnListItem>
          <ColumnListItem onClick={(_) => navigate("/contact")}>
            Contact Us
          </ColumnListItem>
          <ColumnListItem onClick={() => {
              props.setOpenStoreLocation(true);
            }}>
            Locale Store
          </ColumnListItem>
        </ColumnList>
        <ColumnList>
          <ColumnListTitle>Follow Us</ColumnListTitle>
          <ColumnListItem>
            <a style={{color:"white"}} target="_blank" href="https://www.linkedin.com/in/extra-cashandcarry-30104120b/">
              Linkedin           
            </a>
          </ColumnListItem>
          <ColumnListItem>
            <a style={{color:"white"}} target="_blank" href="https://www.instagram.com/extra_cash_carry/?igshid=OGQ5ZDc2ODk2ZA%3D%3D">
              Instagram
            </a>
          </ColumnListItem>
          <ColumnListItem>
            <a style={{color:"white"}} target="_blank" href="https://www.tiktok.com/@extracashandcarry1?_t=8hljTVkk5Vx&_r=1">
              Tiktok
            </a>
          </ColumnListItem>
          <ColumnListItem>
            <a style={{color:"white"}} target="_blank" href="https://www.facebook.com/ExtraCashandCarry/?locale=en_GB">
              Facebook
            </a>
          </ColumnListItem>
        </ColumnList>
       
      </Top>
      <Divider style={{ margin: "5px 0" }} />

      <Bottom>
      <p>Powered By <a target="_blank" href="http://www.orcabs.com">Orca Business Solutions Ltd.  -  </a> Extra Cash And Carry © 2024</p>
      </Bottom>
      <Divider style={{ margin: "5px 0" }} />
    </Container>
  );
}

export default Footer;

/*
<ColumnList>
          <ColumnListTitle>Follow Us</ColumnListTitle>
          <ColumnListItem>
            <Facebook style={{ marginRight: "5px" }} />
            <a style={{color:"white"}} href="https://www.instagram.com/extra_cash_and_carry/">
              Facebook
            </a>
          </ColumnListItem>
          <ColumnListItem>
            <Instagram style={{ marginRight: "5px" }} />
            <a style={{color:"white"}} href="https://www.instagram.com/extra_cash_and_carry/">
              Instagram
            </a>
          </ColumnListItem>
          
        </ColumnList>
        <ColumnList>
          <ColumnListTitle >About Us</ColumnListTitle>
          <ColumnListItem onClick={(_) => navigate("/route=contact")}>
            Contact
          </ColumnListItem>
          <ColumnListItem onClick={(_) => navigate("/route=about")}>
            About
          </ColumnListItem>
        </ColumnList>
*/