import styled from "styled-components";

const FeaturesImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  left: 0px;
  right: 0px;
  margin-top: 1rem;
  @media only screen and (max-width: 768px) {
    margin-top: 0rem;
    flex-direction: row;
    flex-wrap:wrap;
  }
`;
const EachFeature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  opacity: 0.85;
  margin: 0rem 2rem;

  & span {
    visibility: hidden;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    letter-spacing: 0.0015em;
    color: #222222;
    @media only screen and (max-width: 968px) {
      font-weight: 400;
      font-size: 0.8rem;
    
  }
  }
  &:hover {
    opacity: 1;
    & span {
      visibility: visible;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 30%;
    margin: 1rem auto;
    
  }
 
`;
const EachFeatureImage = styled.img`
  width: 3.25rem;
  height: 3.25rem;
  object-fit: contain;
  @media only screen and (max-width: 768px) {
    height: 150px;
    
  }
`;

function FeaturesImageContent() {
  return (
      <FeaturesImage>
        <EachFeature>
          <EachFeatureImage src="/catalog/FeatureImages/Freedelivery.png"></EachFeatureImage>
        </EachFeature>
        <EachFeature>
          <EachFeatureImage src="/catalog/FeatureImages/wesupport.png"></EachFeatureImage>
        </EachFeature>
        <EachFeature>
          <EachFeatureImage src="/catalog/FeatureImages/Safequality.png"></EachFeatureImage>
        </EachFeature>
      </FeaturesImage>
  );
}

export default FeaturesImageContent;
