/* eslint-disable jsx-a11y/iframe-has-title */

import styled from "styled-components";
import useForm from "../../utilities/hooks/useForm";
import {validateInfo} from '../../utilities/helpers';
import { colors } from "../../res/values/values";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
//#region Styles

const Container = styled.div`

`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const ContactUsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;  
  & p {
    color: #777;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    text-decoration: none;
    display: block;
  }
  & h1 {
    margin-bottom: 2rem;
    color: ${colors.primaryColor};
    font-size: 24px;
    //font-weight: 700;
    text-transform: capitalize;
  }
  & h4 {
    color: ${colors.primaryColor};
    margin-bottom:.5rem;
    font-size: 15px;
    line-height: 1.58em;
    //text-transform: capitalize;
    letter-spacing: 1px;
    //font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const MultiInput = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  height: 15vw;
  border-color: lightgray;
  border-style: solid;
  text-align: start;
  &:focus {
    outline: none !important;
    border-color: ${colors.primaryColor};
  }
`;
const Button = styled.input`
  color: ${colors.whiteColor};
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding: 15px 20px;
  background-color: ${colors.primaryColor};;
  opacity: 80%;
  margin-bottom: 10px;
  cursor: pointer;
`;

//#endregion
const Alert =forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ContactContent(props) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const sendContactMail = async (values) => {


    let url = `${process.env.REACT_APP_API}/mail`;
    let rv = false;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        mail: values.email,
        name: values.name,
        message: values.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setOpen(true);
        setTimeout(function() {navigate("/")}, 2000);
      })
      .catch((err) => {
        let error = JSON.parse(err.message);
      });
  };

  const { handleChange, handleSubmit, values, errors } = useForm(
    sendContactMail,
    validateInfo
  );
  return (
    <Container>


      <div>
      <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2477.857388208724!2d-0.06522192308535511!3d51.60750160338531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761e86c82608fb%3A0x25684fb97aee60ff!2sExtra%20Cash%20and%20Carry!5e0!3m2!1sen!2str!4v1694705089292!5m2!1sen!2str" 
      width="100%"
       height="450" 
       style={{border:0,marginTop:5}} 
       allowfullscreen="" 
       loading="lazy" ></iframe>
      </div>
      <ContactUsDiv>
        <DivContent>
          <h1> Contact Us</h1>
          <h4>
            Extra Cash and Carry
          </h4>
          <h4>
            Address: Unit A. Brantwood Road N170YD London, United Kingdom
          </h4>
          <h4>
            Phone: 02039 376 970
          </h4>
          <h4>
            WhatsApp Online Sale Line: 07494 214 847
          </h4>
          <h4>
            E-mail: sales@extracashandcarry.com
          </h4>
          <h4>
            Opening times: 24 Hours
          </h4>
        
        </DivContent>
        <DivContent>
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              onChange={handleChange}
              type="text"
              placeholder="email"
              value={values.email}
            />
            {errors.email && <p style={{color:'red'}}>{errors.email}</p>}
            <Input
              name="name"
              onChange={handleChange}
              type="text"
              placeholder="name"
              value={values.name}
            />
            {errors.name && <p style={{color:'red'}}>{errors.name}</p>}
            <MultiInput
              name="message"
              onChange={handleChange}
              type="text"
              placeholder="your message (optional)"
              value={values.message}
            />
            {errors.message && <p style={{color:'red'}}>{errors.message}</p>}
            <Button type="submit" value="SUBMIT"></Button>
          </Form>
        </DivContent>
      </ContactUsDiv>
      <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Sending email successful !
        </Alert>
      </Snackbar>
    </Stack>
    </Container>
  );
}

export default ContactContent;
