import React, { useEffect } from "react";
import styled from "styled-components";
import HomeContent from "../components/PagesComponents/HomeContent";
import ImageSlider from "../components/GlobalComponents/ImageSlider";
import { changeAndAddMetaTags } from "../utilities/helpers";
const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  margin: .1rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  width: 20%;
  margin-right: 1rem;
  @media only screen and (max-width: 768px) {
    width: 0;
    height:0;
  }
`;
const Right = styled.div`
  width: 100%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MainContainer>
      {changeAndAddMetaTags('Extra Cash and Carry | London','Extra Cash and Carry | London',document.URL)}
      <Right>
        <Container>
          <ImageSlider type={"home"} />
          <HomeContent/>      
        </Container>
      </Right>
    </MainContainer>
  );
};

export default Home;


/*
<Container>
      <ImageSlider type={"home"} />
      <HomeContent/>      
    </Container>
*/