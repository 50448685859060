/* eslint-disable jsx-a11y/no-distracting-elements */
import styled from "styled-components";
import { AddLocation, LocalOffer, Instagram,Facebook,Mail } from "@material-ui/icons";
import { colors } from "../../res/values/values";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex:1 ;
  flex-direction:row;
  background-color: #F4F6F8;
  color: ${colors.whiteColor};
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  min-height: 2rem;
`;

const Row = styled.div`
  font-size: .85rem;
  max-width: 1280px;
  font-weight: 500;
  letter-spacing: 1px;
  display: flex;
  flex-direction:row ;
  padding-inline: 1.5rem;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: 969px) {
    flex-direction: column;
  }
`;
const RowElemet = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  @media only screen and (max-width: 969px) {
    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }
  
`;
const RowElement1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    color: #212B36;
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    @media only screen and (max-width: 969px) {
      font-size: 0.675rem;
    }
  }
`
const RowElement = styled.div`
  margin-left: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    color: #212B36;
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media only screen and (max-width: 969px) {
      font-weight: 400;
    }
  }
`;
const RowElementA = styled.a`
  margin-left: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: #212B36;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: 969px) {
    font-weight: 400;
  }
`;
const Divider = styled.div`
  border-left: 2px solid white;
  opacity: 0.6;
  margin-left: 5px;
`

function Announcement() {
  const navigate = useNavigate()
  const handleLinkClick = (event, route) => {
    event.preventDefault();
    //window.location.href=route;
    navigate(route);
  };
  return (
    <Container>
      <Row>
        <RowElemet>
          <RowElement1>
          <p>Open 24/7 to Public Unit A, Brantwood Road N17 0YD - Order by Phone 24/7 0203 937 69 70</p>
          </RowElement1>
        </RowElemet>
        <RowElemet>
          <RowElementA
          href={"/about"}
          onClick={(event) => handleLinkClick(event, '/about')}
          title={"about"}>
            About Us
            <Divider/>
          </RowElementA>
          <RowElementA 
          href={"/contact"}
          onClick={(event) => handleLinkClick(event, '/contact')}
          title={"contact"}>
            Contact Us
            <Divider/>
          </RowElementA>
        </RowElemet>
      </Row>     
    </Container>
  );
}
export default Announcement;
/*
<RowElement>
            <a style={{color:"white"}} href="https://www.facebook.com/ExtraCashandCarry/">
              <Facebook/>
            </a>
          </RowElement>
          <RowElement>
            <a style={{color:"white"}} href="https://www.instagram.com/extra_cash_and_carry/">
              <Instagram/>
            </a>
          </RowElement>
          <RowElement>
            <a style={{color:"white"}} href="mailto:sales@extracashandcarry.com">
              <Mail/>
            </a>
          </RowElement>
*/