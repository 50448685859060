import React, { useEffect } from "react";
import styled from "styled-components";
import ContactContent from "../components/PagesComponents/ContactContent";
import { useNavigate } from "react-router-dom";
import { changeAndAddMetaTags } from "../utilities/helpers";

const Container = styled.div`
  width: 100%;
  max-width: 1280px;
`;
const Contact = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  },[])
  useEffect(() => {
    changeAndAddMetaTags('Contact | Extra Cash and Carry','Contact | Extra Cash and Carry',document.URL)
  }, []);
  const sendMail = async (values) => {
    let url = `${process.env.REACT_APP_API}/mail`;
    let rv = false;
    await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        mail: values.email,
        name: values.name,
        message: values.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        rv = true;
        navigate("/");
      })
      .catch((err) => {
        rv = false;
      });
    //return rv;
  };
  return (
    <Container>
      <ContactContent sendMail={sendMail} />
    </Container>
  );
};

export default Contact;
