export const colors={
    primaryColor:'#212B36',
    //D91419
    secondryColor:'#4ED4FF',
    whiteColor:'#f2f2f2',
    lightcolor:'#a1a1a1',
    darkcolor:'#002950',
    salecolor:'#3f793e',
    weboffercolor:'#CD1B1B',
    errorcolor:'#CD1B1B'
}
export const STRIPE_KEY=""

export const API_URL=''




//1e54aa -- expo mid color
//008080 -- orca color