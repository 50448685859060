import React, { useState, useEffect } from "react";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { colors } from "../../res/values/values";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manufacturerActions from "../../redux/actions/manufacturerActions";
import { useNavigate } from "react-router-dom";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";

//#region Styles
const BodyDiv = styled.div`
  display: grid;

  margin: 0 auto;
  position: relative;
  overflow: hidden;
  //margin: 2rem;
  max-width: 1280px;
  margin-top: 2.813rem;
 
  @media only screen and (max-width: 768px) {
    margin: 0rem;
    //padding: 10px;
    margin-bottom: 0;
  }
`;
const Glider = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  //padding: 0px 10px;
  //box-shadow: 0px 3px 15px -1px rgb(0 0 0 / 10%);
`;
const Glide = styled.div`
  display: flex;
  min-width: ${(props) => (props.listSize > 5 ? "16.7%" : "16.7%")};
  height: 7.5rem;
  transition: 0.5s;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  //border: 1px solid #f2f2f2;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: 480px) {
    min-width: 50%;
  }
  @media only screen and (min-width: 480px) and (max-width: 720px) {
    min-width: 50%;
  }
  @media only screen and (min-width: 720px) and (max-width: 972px) {
    min-width: 33%;
  }
`;
const Poster = styled.img`
  width: 189px;
  height: 131px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
`;
const RightButton = styled.div`
  z-index: 2;
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition - 25}%;
  right: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
`;
const LeftButton = styled.div`
  z-index: 2;
  position: absolute;
  color: ${(props) => (props.color === "black" ? "#000" : "#000")};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  top: ${(props) => props.heightPosition - 25}%;
  left: ${(props) => props.widthPosition}%;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4);
  height: 35px;
`;
const TitleContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;
const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  width: auto;
  margin-top: 20px;
  //margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 600;
  color: ${colors.primaryColor};
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const BrandName = styled.span`
  width: auto;
  font-size: 1.3rem;
  font-weight: 700;
  color: ${colors.primaryColor};
`;
//#endregion
function BrandsCarousel(props) {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  const [timeId, setTimeId] = useState("");
  useEffect(() => {
    const getBrands = async () => {
      if (props.manufacturers.length === 0) await props.actions.getBrands();
      if (timeId !== "") clearTimeout(timeId);
      let id = setTimeout(() => {
        let elementCount = 0;
        if (width <= 480) elementCount = 2;
        else if (width <= 720) elementCount = 2;
        else if (width <= 972) elementCount = 3;
        else {
          if (props.manufacturers.length > 5) elementCount = 6;
          else elementCount = props.manufacturers.length;
        }

        value === -100 * (props.manufacturers.length - elementCount)
          ? setValue(0)
          : setValue(value - 100);
      }, 4000);
      setTimeId(id);
    };
    getBrands();
  }, [value,props.manufacturers]);

  const moveBehind = () => {
    let elementCount = 0;
    if (width <= 480) elementCount = 2;
    else if (width <= 720) elementCount = 2;
    else if (width <= 972) elementCount = 3;
    else {
      if (props.manufacturers.length > 5) elementCount = 6;
      else elementCount = props.manufacturers.length;
    }

    value === -100 * (props.manufacturers.length - elementCount)
      ? setValue(0)
      : setValue(value - 100);
  };
  const moveAhead = () => {
    let elementCount = 0;
    if (width <= 480) elementCount = 2;
    else if (width <= 720) elementCount = 2;
    else if (width <= 972) elementCount = 3;
    else {
      if (props.manufacturers.length > 5) elementCount = 6;
      else elementCount = props.manufacturers.length;
    }
    value === 0
      ? setValue(-100 * (props.manufacturers.length - elementCount))
      : setValue(value + 100);
  };
  const rootBrands = (brand) => {
    navigate(`/search?${brand ? `brand=${brand}` : ""}`);
  };
  return (
    <BodyDiv>
      {props.manufacturers.length > 0 && (
        <>
        
          <Glider>
            {props.manufacturers.map((brand, index) => {
              return (
                <Glide
                  listSize={props.manufacturers.length}
                  key={index}
                  style={{ transform: `translateX(${value}%)` }}
                  onClick={() => rootBrands(brand.code)}
                >
                  {brand.image ? (
                    <Poster
                      key={brand.code}
                      className="poster"
                      src={`./catalog/Brands/${brand.code}.jpg`}
                    />
                  ) : (
                    <BrandName>{brand.name}</BrandName>
                  )}
                </Glide>
              );
            })}
          </Glider>
        </>
      )}
    </BodyDiv>
  );
}
/*
  <Poster
    key={brand.code}
    className="poster"
    src={`./catalog/Brands/${brand.code}.png`}
  />
*/
function mapStateToProps(state) {
  return {
    manufacturers: state.manufacturerReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBrands: bindActionCreators(
        manufacturerActions.getManufacturerList,
        dispatch
      ),
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandsCarousel);
