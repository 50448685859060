import React, { useEffect } from "react";
import styled from "styled-components";
import AboutContent from "../components/PagesComponents/AboutContent";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import ImageSlider from "../components/GlobalComponents/ImageSlider";
import { changeAndAddMetaTags } from "../utilities/helpers";
const Content = styled.div`
  width: ${(props) => props.width}px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
`;
const Container = styled.div`
  max-width: 1280px;
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  cursor:pointer;
  @media only screen and (max-width: 768px) {
    //height: 15rem;
    object-fit: contain;
  }
  
`;
const About = () => {
  const { width } = useWindowWidthAndHeight();
  useEffect(() => {
    changeAndAddMetaTags('About Us | Extra Cash and Carry','About Us | Extra Cash and Carry',document.URL)
  }, [])
  
  return (
    <Content width={width}>
    <Image
             alt=""
             loading="lazy"
             title="aboutus"
             src={`/catalog/Banners/aboutus.jpg`} />
    <Container>
      
      {/* <ImageSlider type={"home"} />*/}
      <AboutContent />
    </Container>
    </Content>
    
  );
};

export default About;
